import React, { useEffect, useState } from "react";
import TadobaContext from "./TadobaContext";
import dayjs from "dayjs";

const TadobaProvider = ({ children }) => {
  const currentMonthStart = dayjs().startOf("month").format("YYYY-MM-DD");
  const threeMonthsAgo = dayjs().subtract(3, "month").startOf("month").format("YYYY-MM-DD");

  const [fromDate, setFromDate] = useState(threeMonthsAgo);
  const [toDate, setToDate] = useState(currentMonthStart);
  const [range, setRange] = useState("Past 3 months");
  
  return (
    <TadobaContext.Provider
      value={{
        fromDate,
        toDate,
        setFromDate,
        setToDate,
        range,
        setRange,
      }}
    >
      {children}
    </TadobaContext.Provider>
  );
};

export default TadobaProvider;
