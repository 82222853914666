import React from 'react';
import Navbar from '../components/Emission/Navbar';
import Sidebar from '../components/Emission/Sidebar';
import EmissionAnalytics from '../components/Emission/EmissionAnalytics/EmissionAnalytics';

const EmissionAnalyticsPage = () => {
  return (
    <div className="emission-page-container">
    <div className="navbar-container">
      <Navbar />
    </div>
    <div className="content-container">
      <div className="emission-sidebar-container">
        <Sidebar />
      </div>
      <div className="main-content-container">
        <EmissionAnalytics />
      </div>
    </div>
  </div>
  )
}

export default EmissionAnalyticsPage
