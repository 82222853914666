import React, { useState, useEffect, useContext, useRef } from "react";
import "../../css/dashboardComparison.css";
import { Checkbox, DatePicker, Space, Divider, Select, Col, Row } from "antd";
import axios from "axios";
import FrequencyModal from "./FrequencyModal";
import DashboardMap from "./DashboardMap";
import { addMonths, format, parseISO } from "date-fns";
import TadobaContext from "../Context/TadobaContext";
import DashboardContext from "../Context/DashboardContext";

// const { RangePicker } = DatePicker;
const { Option } = Select;

const DashboardComparisonMap = () => {
  //{ fromDate, toDate, setFromDate, setToDate, selectedRange, setSelectedRange, selectedCause, mapCoords, isLoading, imageUrl1, imageUrl2 }
  const api = useContext(TadobaContext);
  const [zoom, setZoom] = useState(10.8);
  const [view, setView] = useState("Coordinates View");
  const [map1Ref, setMap1Ref] = useState(null);
  const [map2Ref, setMap2Ref] = useState(null);

  const [causes, setCauses] = useState([]);
  const [selectedCause, setSelectedCause] = useState("");
  const [fromDate, setFromDate] = useState(api.fromDate);
  const [toDate, setToDate] = useState(api.toDate);
  const [selectedRange, setSelectedRange] = useState(api.range);
  const [forestName, setForestName] = useState("");
  const [apiResponse, setapiResponse] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [isLoading1, setIsLoading1] = useState(false);
  const [isLoading2, setIsLoading2] = useState(false);
  const [imageUrl1, setImageUrl1] = useState("");
  const [imageUrl2, setImageUrl2] = useState("");
  const [dateRange, setDateRange] = useState({
    fromDate: fromDate,
    toDate: toDate,
  });

  const [mapCoords, setMapCoords] = useState([
    { lat: 21.572004, lng: 79.19164 }, //21.7224183892942,79.2317819977019
  ]);
  const [center, setCenter] = useState({ lat: 21.572004, lng: 79.19164 });

  const {type, setType} = useContext(DashboardContext);

  const handleMapLoad1 = (map) => {
    setMap1Ref(map);
  };

  const handleMapLoad2 = (map) => {
    setMap2Ref(map);
  };

  const handleCenterChanged = (newCenter) => {
    if (center.lat !== newCenter.lat || center.lng !== newCenter.lng) {
      setCenter(newCenter);
    }
  };

  const handleZoomChanged = (newZoom) => {
    if (zoom !== newZoom) {
      setZoom(newZoom);
    }
  };

  useEffect(() => {
    if (fromDate && toDate) {
      fetchMapData();
    }
  }, []);

  let cause_type=2;

  const fetchMapData = async () => {
    if(type === "Deforestation"){
      cause_type=1;
    }
    try {
      let fromdate = fromDate.replaceAll("/", "-");
      let todate = toDate.replaceAll("/", "-");
      const requestOptions = {
        params: {
          from_date: fromdate,
          to_date: todate,
          cause_name: selectedCause,
          cause_type: cause_type,
          ...(forestName && { forest_name: forestName }),
          
        },
        headers: {
          Authorization: `Bearer ${localStorage.getItem("access_key")}`,
        },
      };
      if (fromDate && toDate) {
        const response = await axios.get(
          `${process.env.REACT_APP_BE_URL}/deforestation/area-analysis-map/`,
          requestOptions
        );
        console.log("forest data are...", response.data.data);
        const coords = response.data.data.map((coord) => ({
          lat: parseFloat(coord.latitude),
          lng: parseFloat(coord.longitude),
          cause: coord.cause_name,
        }));
        setMapCoords(coords);
        setapiResponse(response.data.data);

        setIsLoading(false);
      }
    } catch (error) {
      console.error("Error fetching map data:", error);
    }
  };

  const getFormattedDate = (date) => {
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const day = String(date.getDate()).padStart(2, "0");
    return `${year}-${month}-${day}`;
  };
  const subtractMonths = (date, months) => {
    const newDate = new Date(date);
    const totalMonths =
      newDate.getFullYear() * 12 + newDate.getMonth() - months;
    const newYear = Math.floor(totalMonths / 12);
    const newMonth = totalMonths % 12;
    newDate.setFullYear(newYear);
    newDate.setMonth(newMonth);

    return newDate;
  };

  const calculatetoDate = (value) => {
    let rangeDate;
    rangeDate = getFormattedDate(subtractMonths(value, 2));
    return rangeDate;
  };

  const calculatefromDate = (value) => {
    let rangeDate;
    let calculatedDate = addMonths(value, parseInt(2, 10));
    rangeDate = format(calculatedDate, "yyyy-MM-dd");
    return rangeDate;
  };
  const getHistoricalUri1 = async () => {
    try {
      const { data } = await axios.get(
        `${process.env.REACT_APP_BE_URL}/deforestation/map_historical_image/`,
        {
          //?coords=79.19164,21.572004&startdate=2021-01-02&enddate=2024-01-02
          params: {
            startdate: calculatetoDate(fromDate),
            enddate: fromDate,
            coords: 79.19164 + "," + 21.572004,
            buffer: 1000,
            step: 0.018,
          },
        }
      );
      console.log("image url length: ", data.length);
      setImageUrl1(data);
      setIsLoading1(false);
    } catch (error) {
      console.error("Error fetching image url:", error);
    }
  };

  const getHistoricalUri2 = async () => {
    try {
      const { data } = await axios.get(
        `${process.env.REACT_APP_BE_URL}/deforestation/map_historical_image/`,
        {
          //?coords=79.19164,21.572004&startdate=2021-01-02&enddate=2024-01-02
          params: {
            startdate: toDate,
            enddate: calculatefromDate(toDate),
            coords: 79.19164 + "," + 21.572004,
            buffer: 1000,
            step: 0.018,
          },
        }
      );
      console.log("image url length: ", data.length);
      setImageUrl2(data);
      setIsLoading2(false);
    } catch (error) {
      console.error("Error fetching image url:", error);
    }
  };

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    const options = { year: "numeric", month: "short" };
    return date.toLocaleDateString("en-US", options);
  };

  const handleViewChange = (value) => {
    setView(value);
  };

  return (
    <div style={{ width: "100%" }}>
      <header
        style={{
          boxShadow: "rgba(0, 0, 0, 0.31) 0px 1px 10px",
          borderRadius: "13px",
          height: "12vh",
        }}
        className="header"
      >
        <div style={{ padding: "2vh 1vw" }} className="header_left">
          <h3
            style={{ fontFamily: "inter" }}
            className="DashboardComparison_map_h3"
          >
            Map Comparison{" "}
            <p
              style={{ fontSize: "16px", fontWeight: "400", color: "#737373" }}
            >
              This page include maps for comparison with historical records.
            </p>
          </h3>
        </div>

        <div className="right_div_map" style={{ padding: "0 1vw" }}>
          <p
            style={{ color: "#A7571D", fontWeight: "500", marginRight: "5px" }}
          >
            Left: <span style={{ color: "grey" }}>{formatDate(fromDate)}</span>
          </p>
          <Divider type="vertical" />
          <p
            style={{
              color: "#00A6BD",
              fontWeight: "500",
              marginRight: "5px",
              marginRight: "5px",
              marginBottom: "3px",
              marginLeft: "5px",
            }}
          >
            Right: <span style={{ color: "black" }}>{formatDate(toDate)}</span>
          </p>
          <Divider type="vertical" />
          <p
            style={{
              color: "#00A6BD",
              fontWeight: "500",
              marginRight: "5px",
              marginBottom: "3px",
              marginLeft: "5px",
            }}
          >
            Frequency: <span style={{ color: "black" }}>{selectedRange}</span>
          </p>

          <FrequencyModal
            title={"right"}
            fromDate={fromDate}
            setFromDate={setFromDate}
            selectedRange={selectedRange}
            setSelectedRange={setSelectedRange}
            toDate={toDate}
            setToDate={setToDate}
            setIsLoading={setIsLoading}
            setIsLoading1={setIsLoading1}
            setIsLoading2={setIsLoading2}
            fetchMapData={fetchMapData}
            getHistoricalUri1={getHistoricalUri1}
            getHistoricalUri2={getHistoricalUri2}
          />

          <Select
            style={{ background: "#E7F0FE", border: "none" }}
            placeholder="Select View"
            onChange={handleViewChange}
          >
            <Option value={"Coordinates View"}>Coordinates View</Option>
            <Option value={"Historical View"}>Historical View</Option>
          </Select>
        </div>
      </header>

      <main className="map-img-container">
        <div className="map-img-wrapper">
          <DashboardMap
            view={view}
            center={center}
            zoom={zoom}
            isLoading={isLoading}
            isLoadingImg={isLoading1}
            imageUrl={imageUrl1}
            onLoad={handleMapLoad1}
            onCenterChanged={handleCenterChanged}
            onZoomChanged={handleZoomChanged}
          />
        </div>
        <div className="map-img-wrapper">
          <DashboardMap
            view={view}
            mapCoords={mapCoords}
            center={center}
            zoom={zoom}
            isLoading={isLoading}
            isLoadingImg={isLoading2}
            imageUrl={imageUrl2}
            onLoad={handleMapLoad2}
            onCenterChanged={handleCenterChanged}
            onZoomChanged={handleZoomChanged}
          />
        </div>
      </main>
    </div>
  );
};

export default DashboardComparisonMap;
