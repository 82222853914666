import React, { useEffect, useState } from 'react';
import axios from 'axios';
import Chart from 'react-apexcharts';

const ThresholdTrend = ({ selectedLocation, selectedTime }) => {
  const [data, setData] = useState([]);
  const [thresholds, setThresholds] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchThresholdData = async () => {
      const timeFrameMap = {
        "Past 1 Month": "1_month",
        "Past 3 Months": "3_months",
        "Past 6 Months": "6_months",
        "Past 1 Year": "1_year",
      };
      const timeParam = timeFrameMap[selectedTime] || "6_months";

      const url = `${process.env.REACT_APP_BE_URL}/emissions/threshold-exceedance-trend/`;
      try {
        const response = await axios.get(url, {
          params: {
            location: selectedLocation,
            timeframe: timeParam,
          },
          headers: {
            Authorization: `Bearer ${localStorage.getItem('access_key')}`,
            'Content-Type': 'application/json',
          },
        });

        const chartData = response.data.map((item) => ({
          name: item.gas,
          value: item.exceedance_count,
          unit: item.unit,
          threshold: item.threshold,
        }));

        setData(chartData);
        setThresholds(response.data);
      } catch (error) {
        setError('Error fetching threshold data');
      } finally {
        setLoading(false);
      }
    };

    fetchThresholdData();
  }, [selectedLocation, selectedTime]);

  const colors = ['#173F5F','#3CAEA3', '#2576B9','#F0C93A','#ED553B'];

  const chartOptions = {
    chart: {
      type: 'bar',
      height: 350,
      toolbar: {
        show: false,
      },
    },
    plotOptions: {
      bar: {
        distributed: true,
        columnWidth: '40%',
        borderRadius: 5,
      },
    },
    dataLabels: {
      enabled: false,
    },
    xaxis: {
      categories: data.map((item) => item.name),
      labels: {
        style: {
          fontSize: '12px', 
        },
      },
    },
    yaxis: {
      labels: {
        style: {
          fontSize: '12px', 
        },
      },
    },
    colors: colors.slice(0, data.length),
    legend: {
      show:false,
    },
  };
  

  const chartSeries = [
    {
      name: 'Exceedance Count',
      data: data.map((item) => item.value),
    },
  ];

  return (
    <div className="trend-section">
      <div className="threshold-heading-box">
        <h3 className="emission-heading">Threshold exceedance trend</h3>
        <p className="section-subtitle">
          No. of places where the threshold frequency was exceeded.
        </p>
      </div>
      <div className="threshold-details">
  <h5>Current thresholds:</h5>
  {!loading && !error && (
    <div className="threshold-para-container">
      <div className="threshold-row">
        {thresholds.slice(0, 3).map((item, index) => (
          <span key={index}>
            {item.gas} : {item.threshold} {item.unit}
            {index < thresholds.slice(0, 3).length - 1 && <span className="dot"> </span>}
          </span>
        ))}
      </div>
      <div className="threshold-row">
        {thresholds.slice(3).map((item, index) => (
          <span key={index}>
            {item.gas} : {item.threshold} {item.unit}
            {index < thresholds.slice(3).length - 1 && <span className="dot"> </span>}
          </span>
        ))}
      </div>
    </div>
  )}
</div>

      <div className="bar-chart-container">
        {loading && (
          <div style={{ fontStyle: 'italic', marginLeft: '1.5rem' }}>Loading</div>
        )}
        {error && (
          <div style={{ color: 'red', marginLeft: '1.5rem' }}>Error: {error}</div>
        )}
        {!loading && !error && (
          <Chart
            options={chartOptions}
            series={chartSeries}
            type="bar"
            height={250}
          />
        )}
      </div>
    </div>
  );
};

export default ThresholdTrend;
