import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { Dropdown, Menu } from 'antd';
import { DownOutlined } from '@ant-design/icons';
import location from '../../../assets/Emission/EmissionAnalytics/location.svg';

const ThresholdExceedAlerts = ({ selectedLocation }) => {
  const [data, setAlertData] = useState([]);
  const [selectedGas, setSelectedGas] = useState('Methane'); // Default selected gas
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  const gasOptions = ['Ozone', 'Sulfur Dioxide', 'Methane', 'Nitrogen Dioxide', 'Carbon Monoxide']; // Gas options

  // Fetch alert data whenever selectedLocation or selectedGas changes
  useEffect(() => {
    const fetchAlertData = async () => {
      const url = `${process.env.REACT_APP_BE_URL}/emissions/threshold-exceed-alerts/`;
      try {
        const response = await axios.get(url, {
          params: {
            location: selectedLocation,
            gas: selectedGas, // Pass the selected gas as a parameter
          },
          headers: {
            Authorization: `Bearer ${localStorage.getItem('access_key')}`,
            'Content-Type': 'application/json',
          },
        });

        setAlertData(response.data?.threshold_exceed_alerts || []);
      } catch (err) {
        console.error('Error fetching threshold data:', err);
        setError('Error fetching threshold data');

        // Adding dummy data in case of an error
        setAlertData([
          {
            location: selectedLocation || "NA",
            gas: "Ozone",
            measurement: 0.1234,
            unit: "mol/m²",
          },
          {
            location: selectedLocation || "NA",
            gas: "Sulfur Dioxide",
            measurement: 0.5678,
            unit: "mol/m²",
          },
          {
            location: selectedLocation || "NA",
            gas: "Methane",
            measurement: 0.003,
            unit: "mol/m²",
          },
        ]);
      } finally {
        setLoading(false);
      }
    };

    fetchAlertData();
  }, [selectedLocation, selectedGas]); // Trigger data fetch when location or gas changes

  const menu = (
    <Menu>
      {gasOptions.map((gas, index) => (
        <Menu.Item 
          key={index} 
          onClick={() => setSelectedGas(gas)} 
          style={{
            backgroundColor: selectedGas === gas ? '#E6F7FF' : '', // Set background for selected gas
          }}
        >
          {gas}
        </Menu.Item>
      ))}
    </Menu>
  );

  return (
    <div className="alerts-section">
      <div className="alerts-title-box">
        <h3 className="emission-heading">Threshold Exceed Alerts</h3>
        <Dropdown
          className="gas-dropdown"
          overlay={menu}
          trigger={['click']}
        >
          <span className="dropdown-label">
            {selectedGas} <DownOutlined style={{ color: '#025864', marginLeft: '8px' }} />
          </span>
        </Dropdown>
      </div>
      <div className="analytics-component-divider"></div>
      {loading ? (
        <div style={{ fontStyle: 'italic', marginLeft: '1.5rem' }}>Loading</div>
      ) : (
        <div className="alerts-row-container">
          {error && <div style={{ color: 'red', marginLeft: '1.5rem' }}>{error}</div>}
          <ul>
            {data.map((item, index) => (
              <li key={index} className="alerts-row">
                <div className="state-row">
                  <img src={location} alt="location" className="loc" />
                  <div className="state">{item.location}</div>
                </div>
                <div className="gas">{item.gas}</div>
                <div className="exceed-value">
                  {item.measurement.toFixed(4)} {item.unit}
                </div>
              </li>
            ))}
          </ul>
        </div>
      )}
    </div>
  );
};

export default ThresholdExceedAlerts;
