import React, { useState, useEffect } from "react";
import axios from 'axios';
import { GoogleMap, LoadScript, Marker } from "@react-google-maps/api";
import { MapKey } from "../../../data";

const IndiaMap = ({ selectedLocation, selectedTime }) => {
  const [mapType, setMapType] = useState("hybrid");
  const [mapHeight, setMapHeight] = useState("64vh");
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchData = async () => {
      const timeFrameMap = {
        "Past 1 Month": "1_month",
        "Past 3 Months": "3_months",
        "Past 6 Months": "6_months",
        "Past 1 Year": "1_year",
      };
      const timeParam = timeFrameMap[selectedTime] || "6_months";

      const url = `${process.env.REACT_APP_BE_URL}/emissions/max-measurements/`;

      try {
        const response = await axios.get(url, {
          params: {
            location: selectedLocation,
            timeframe: timeParam,
          },
          headers: {
            Authorization: `Bearer ${localStorage.getItem("access_key")}`,
            "Content-Type": "application/json",
          },
        });
        setData(response.data.data); // Update data with API response
      } finally {
        setLoading(false); // End loading
      }
    };

    fetchData();
  }, [selectedLocation, selectedTime]);

  useEffect(() => {
    const updateMapHeight = () => {
      const screenHeight = window.innerHeight;
      if (screenHeight >= 800) {
        setMapHeight("71vh"); // Ideal height for Nest Hub Max
      } else {
        setMapHeight("64vh"); // Default height for smaller screens
      }
    };
    updateMapHeight(); // Set initial height
    window.addEventListener("resize", updateMapHeight); // Update height on screen resize
    return () => {
      window.removeEventListener("resize", updateMapHeight);
    };
  }, []);

  const containerStyle = {
    width: "98%",
    height: mapHeight,
    margin: "15px",
    borderRadius: "24px",
  };

  const center = {
    lat: 28.6139, // New Delhi latitude
    lng: 77.209, // New Delhi longitude
  };

  // Legend for different gases
  const pollutantLegendData = [
    { name: "Carbon Monoxide (CO)", color: "#173F5F" },
    { name: "Nitrogen Dioxide (NO₂)", color: "#3CAEA3" },
    { name: "Ozone (O₃)", color: "#2576B9" },
    { name: "Sulfur Dioxide (SO₂)", color: "#F0C93A" },
    { name: "Methane (CH₄)", color: "#ED553B" },
  ];

  // Helper to get the color for each gas
  const getGasColor = (gas) => {
    const legendItem = pollutantLegendData.find((item) =>
      gas.toLowerCase().includes(item.name.split(" ")[0].toLowerCase())
    );
    return legendItem ? legendItem.color : "#000";
  };

  return (
    <div className="google-map">
      {/* Legend */}
      <div className="chart-legend-map">
        {pollutantLegendData.map((item, index) => (
          <div key={index} className="legend-row-map">
            <div className="legend-item-map" style={{ color: item.color }}>
              <span
                className="legend-dot-map"
                style={{ backgroundColor: item.color }}
              ></span>
              <span className="legend-text-map">{item.name}</span>
            </div>
          </div>
        ))}
      </div>
      {/* Map */}
      <LoadScript googleMapsApiKey={MapKey}>
        <GoogleMap
          mapContainerStyle={containerStyle}
          center={center}
          zoom={10}
          mapTypeId={mapType}
        >
          {/* Markers */}
          {!loading &&
            data.map((item, index) => (
              <Marker
                key={index}
                position={{
                  lat: item.latititude,
                  lng: item.longitude,
                }}
                icon={{
                  url: `data:image/svg+xml;charset=UTF-8,${encodeURIComponent(`
                    <svg xmlns="http://www.w3.org/2000/svg"  
                      width="${Math.floor(Math.random() * 10) + 20}" 
                      height="${Math.floor(Math.random() * 10) + 20}"  
                      fill="${getGasColor(item.gas)}" 
                      viewBox="0 0 16 16">
                      <circle cx="8" cy="8" r="8" />
                    </svg>`)}`,
                }}
                title={`${item.gas}\n${item.max_location}`}
              />
            ))}
        </GoogleMap>
      </LoadScript>
    </div>
  );
};

export default IndiaMap;
