import React, { useState, useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import logo1 from '../../assets/Emission/Sidebar/logo1.svg';
import logo2 from '../../assets/Emission/Sidebar/logo2.svg';
import logo3 from '../../assets/Emission/Sidebar/logo3.svg';
import logo4 from '../../assets/Emission/Sidebar/logo4.svg';
import logo5 from '../../assets/Emission/Sidebar/logo5.svg';
import logo6 from '../../assets/Emission/Sidebar/logo6.svg';
import logout from '../../assets/Emission/Sidebar/logout.svg';

const Sidebar = () => {
  const [activeLogo, setActiveLogo] = useState(1);
  const [activePage, setActivePage] = useState('');
  const navigate = useNavigate();
  const location = useLocation();

  const logoData = [
    { id: 1, src: logo1, alt: 'Emission Analytics', route: '/dashboard/emission' },
    { id: 2, src: logo2, alt: 'Map-wise Analytics', route: '/map-wise' },
    // { id: 3, src: logo3, alt: 'Comparison overtime' },
    // { id: 4, src: logo4, alt: 'Report Generation', route: '/report' },
    { id: 5, src: logo5, alt: 'Admin Profile', route: '/profile'},
    // { id: 6, src: logo6, alt: 'Settings' },
  ];

  // Update activeLogo and activePage based on current location
  useEffect(() => {
    const currentPath = location.pathname;
    const activeLogoItem = logoData.find((logo) => logo.route === currentPath);
    setActiveLogo(activeLogoItem ? activeLogoItem.id : null);
    setActivePage(activeLogoItem ? activeLogoItem.alt : '');
  }, [location.pathname, logoData]);

  const handleLogoClick = (logo) => {
    setActiveLogo(logo.id);
    if (logo.route) {
      navigate(logo.route);
    }
  };

  const handleLogout = () => {
    localStorage.clear();
    sessionStorage.clear();
    navigate('/');
  };

  return (
    <div className="emission-sidebar-container">
      {logoData.map((logo) => (
        <div
          key={logo.id}
          className={`sidebar-logo ${activeLogo === logo.id ? 'active' : ''}`}
          onClick={() => handleLogoClick(logo)}
          title={logo.alt}
        >
          <img src={logo.src} alt={logo.alt} />
        </div>
      ))}
      <div 
        className="sidebar-logo-logout" 
        onClick={handleLogout}
        title="Logout"
        >
        <img src={logout} alt="logout" />
      </div>
    </div>
  );
};

export default Sidebar;
