import React from 'react'
import noReport from '../../../assets/Emission/Report/null.svg';
import addReport from '../../../assets/Emission/Report/add-report.svg';

const ReportDashboard = () => {
  return (
    <div className='report-container'>
        <div className="center-container">
            <div className="content-box">
                <img src={noReport} alt="No Report" className="report-icon" />
                <h5 className='report-heading'>No reports generated yet</h5>
                <p>
                    Click the button below to generate the report and view the records here.
                </p>
                <button className="generate-btn">
                    <img src={addReport} alt="Add Report" className="add-report-icon" />
                    <p>Generate report</p>
                </button>
            </div>
        </div> 
    </div>
  )
}

export default ReportDashboard
