import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Dropdown, Menu } from 'antd';
import { DownOutlined } from '@ant-design/icons';
import { ReactComponent as CalendarIcon } from '../../../assets/Emission/MapAnalytics/calendar.svg';
import location from '../../../assets/Emission/EmissionAnalytics/location.svg';
import GHGRate from './GHG_Rate.jsx';
import EmissionTrend from './EmissionTrend.jsx';

const EmissionAnalytics = () => {
  const [selectedLocation, setSelectedLocation] = useState('Delhi');
  const [selectedTime, setSelectedTime] = useState('Past 6 Months');
  const [locations, setLocations] = useState([]);

  useEffect(() => {
    const fetchLocations = async () => {
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_BE_URL}/emissions/areas/`,
          {
            headers: {
              Authorization: `Bearer ${localStorage.getItem('access_key')}`,
            },
          }
        );
        // Sort locations alphabetically before setting them in state
        const sortedLocations = response.data.areas.sort((a, b) => a.localeCompare(b));
        setLocations(sortedLocations);
      } catch (error) {
        console.error('Error fetching locations:', error);
      }
    };

    fetchLocations();
  }, []);

  // Menu for location dropdown
  const locationMenu = (
    <Menu
      onClick={(e) => {
        setSelectedLocation(e.key); // Update the selected location
      }}
    >
      {locations.map((location) => (
        <Menu.Item
          key={location}
          style={{
            backgroundColor: selectedLocation === location ? '#E6F7FF' : '',
          }}
        >
          {location}
        </Menu.Item>
      ))}
    </Menu>
  );

  // Menu for time filter dropdown
  const timeMenu = (
    <Menu
      onClick={(e) => {
        if (e.key === '1_month') setSelectedTime('Past 1 Month');
        else if (e.key === '3_months') setSelectedTime('Past 3 Months');
        else if (e.key === '6_months') setSelectedTime('Past 6 Months');
        else if (e.key === '1_year') setSelectedTime('Past 1 Year');
      }}
    >
      <Menu.Item
        key="1_month"
        style={{
          backgroundColor: selectedTime === 'Past 1 Month' ? '#E6F7FF' : '',
        }}
      >
        Past 1 Month
      </Menu.Item>
      <Menu.Item
        key="3_months"
        style={{
          backgroundColor: selectedTime === 'Past 3 Months' ? '#E6F7FF' : '',
        }}
      >
        Past 3 Months
      </Menu.Item>
      <Menu.Item
        key="6_months"
        style={{
          backgroundColor: selectedTime === 'Past 6 Months' ? '#E6F7FF' : '',
        }}
      >
        Past 6 Months
      </Menu.Item>
      <Menu.Item
        key="1_year"
        style={{
          backgroundColor: selectedTime === 'Past 1 Year' ? '#E6F7FF' : '',
        }}
      >
        Past 1 Year
      </Menu.Item>
    </Menu>
  );

  return (
    <div className="emission-analytics-container">
      <div className="emission-row">
        <div className="emission-heading">
          <img src={location} alt="location" className="location-icon" />
          <h3>{selectedLocation}</h3>
        </div>
        <div className="emission-input-dropdown-container">
          {/* Dropdown for location */}
          <Dropdown overlay={locationMenu} trigger={['click']}>
            <div className="emission-dropdown-container">
              <span className="emission-dropdown-label">{selectedLocation}</span>
              <DownOutlined style={{ color: '#025864' }} />
            </div>
          </Dropdown>
          {/* Dropdown for time filter */}
          <Dropdown overlay={timeMenu} trigger={['click']}>
            <div className="emission-dropdown-container">
              <CalendarIcon style={{ marginRight: '8px' }} />
              <span className="emission-dropdown-label">{selectedTime}</span>
              <DownOutlined style={{ color: '#025864' }} />
            </div>
          </Dropdown>
        </div>
      </div>
      <div className="emission-divider"></div> {/* Horizontal line */}

      <GHGRate selectedLocation={selectedLocation} selectedTime={selectedTime} />
      <div className="emission-chart-full">
        <EmissionTrend selectedLocation={selectedLocation} selectedTime={selectedTime} />
      </div>
    </div>
  );
};

export default EmissionAnalytics;
