import React, { useEffect, useState } from 'react';
import axios from 'axios';
import userImg from '../../../assets/Emission/Navbar/profile-pic.jpg';
import { Switch, Modal, Button } from 'antd';
import { QRCodeCanvas } from 'qrcode.react';

const ProfileDashboard = () => {
    const [userDetails, setUserDetails] = useState(null);

    useEffect(() => {
      // Fetch user details
      const fetchUserDetails = async () => {
        try {
          const response = await axios.get(
            `${process.env.REACT_APP_BE_URL}/user_profile/profile/`,
            {
              headers: {
                Authorization: `Bearer ${localStorage.getItem('access_key')}`,
              },
            }
          );
          setUserDetails(response.data); // Set the fetched user details
        } catch (error) {
          console.error('Error fetching user details:', error);
        }
      };
  
      fetchUserDetails();
    }, []);
  
  return (
    <div className='user_profile_main'>
      <div className="user_profile_container">
        <div className="user_profile_elem1">
          <div className="user__profile__img__box">
              <div className="user_profile_img">
                <img src={userImg} alt="user" />
              </div>
              <div className="user_profile_other_detail_box">
                <div className="user_profile_detail">
                  <span>Email ID</span>
                  <span>{userDetails ? `${userDetails.email}  ` : 'Email id'}</span>
                </div>
                <div className="user_profile_detail">
                  <span>Contact Number</span>
                  <span>{userDetails ? `${userDetails.phone_number}  ` : 'Contact Number'}</span>
                </div>
                <div>
                <span className="user_profile_2fa">2-Factor Authentication (2FA)</span> 
                {/* <Switch
                  className="ml-2"
                  onChange={handleSwitchChange}
                  checked={totpEnabled}
                  checkedChildren="Enable"
                  unCheckedChildren="Disable"
                />
                
                <Modal
                  open={isModalOpen}  // Modal visibility
                  onCancel={handleCancel}  // Close modal on cancel
                  footer={null}  // No footer (buttons)
                  className="totp-modal" // Custom class to style the modal
                >
                  <div className="totp-modal-content">
                    <p>Scan this QR code with your Google Authenticator app:</p>
                    <div className="qr-code-container">
                      <QRCodeCanvas value={generateTotpUri()} size={200} /> 
                    </div>
                  </div>
                </Modal> */}
                </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default ProfileDashboard
