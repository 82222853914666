import React from 'react';
import Navbar from '../components/Emission/Navbar';
import Sidebar from '../components/Emission/Sidebar';
import ReportDashboard from '../components/Emission/Report/ReportDashboard';

const ReportPage = () => {
  return (
    <div className="emission-page-container">
    <div className="navbar-container">
      <Navbar />
    </div>
    <div className="content-container">
      <div className="emission-sidebar-container">
        <Sidebar />
      </div>
      <div className="main-content-container">
        <ReportDashboard />
      </div>
    </div>
  </div>
  )
}

export default ReportPage
