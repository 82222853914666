import React from 'react'
import Navbar from '../components/Emission/Navbar';
import Sidebar from '../components/Emission/Sidebar';
import Dashboard from '../components/Emission/Profile/ProfileDashboard';

const ProfilePage = () => {
  return (
    <div className="emission-page-container">
    <div className="navbar-container">
      <Navbar />
    </div>
    <div className="content-container">
      <div className="emission-sidebar-container">
        <Sidebar />
      </div>
      <div className="main-content-container">
        <Dashboard />
      </div>
    </div>
  </div>
  )
}

export default ProfilePage
