import React, { useState, useEffect } from "react";
import axios from "axios";

const SubRegion = ({ selectedLocation, selectedTime }) => {
  const [data, setRegionData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      const timeFrameMap = {
        "Past 1 Month": "1_month",
        "Past 3 Months": "3_months",
        "Past 6 Months": "6_months",
        "Past 1 Year": "1_year",
      };
      const timeParam = timeFrameMap[selectedTime] || "6_months";

      const url = `${process.env.REACT_APP_BE_URL}/emissions/gas-measurements-for-sub-regional/`;
      try {
        const response = await axios.get(url, {
          params: {
            location: selectedLocation,
            timeframe: timeParam,
          },
          headers: {
            Authorization: `Bearer ${localStorage.getItem("access_key")}`,
            "Content-Type": "application/json",
          },
        });

        const mappedData = response.data.data.map((item) => {
          return {
            location: item.location,
            co: {
              value: item["Carbon Monoxide"],
              trend: item["Carbon Monoxide Diff"],
            },
            so2: {
              value: item["Sulfur Dioxide"],
              trend: item["Sulfur Dioxide Diff"],
            },
            ch4: {
              value: item["Methane"],
              trend: item["Methane Diff"],
            },
            no2: {
              value: item["Nitrogen Dioxide"],
              trend: item["Nitrogen Dioxide Diff"],
            },
            o3: {
              value: item["Ozone"],
              trend: item["Ozone Diff"],
            },
          };
        });

        setRegionData(mappedData);
      } catch (err) {
        setError("Failed to fetch data. Displaying dummy data.");
        // Dummy data to be displayed in case of error
        const dummyData = [
          {
            location: selectedLocation || "NA",            
            co: { value: "10", trend: "-0.5" },
            so2: { value: "5", trend: "0.3" },
            ch4: { value: "2", trend: "-0.2" },
            no2: { value: "4", trend: "0.1" },
            o3: { value: "3", trend: "-0.1" },
          },
          {
            location: selectedLocation || "NA",
            co: { value: "8", trend: "0.2" },
            so2: { value: "6", trend: "-0.4" },
            ch4: { value: "3", trend: "0.1" },
            no2: { value: "7", trend: "-0.3" },
            o3: { value: "5", trend: "0.0" },
          },
        ];
        setRegionData(dummyData); // Set dummy data if error occurs
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, [selectedLocation, selectedTime]);

  if (loading) return <div>Loading...</div>;

  // Function to determine the trend color and indicator (▲ or ▼) based on the value
  const getTrendInfo = (trend) => {
    const trendValue = parseFloat(trend);
    if (isNaN(trendValue)) return { color: "", trendSymbol: "" }; // No color or symbol if trend is not a number

    return trendValue < 0
      ? { color: "#1FC16B", trendSymbol: "▼" } // Green for negative, ▼ for down trend
      : { color: "#FB3748", trendSymbol: "▲" }; // Red for positive, ▲ for up trend
  };

  return (
    <div className="sub-regional-table-container" style={{ padding: "20px" }}>
      <table className="sub-regional-table" style={{ width: "100%", borderCollapse: "collapse" }}>
        <thead>
          <tr style={{ textAlign: "left", borderBottom: "1px solid #ddd" }}>
            <th>Region</th>
            <th>Carbon Monoxide (CO)</th>
            <th>Sulfur Dioxide (SO₂)</th>
            <th>Methane (CH₄)</th>
            <th>Nitrogen Dioxide (NO₂)</th>
            <th>Ozone (O₃)</th>
          </tr>
        </thead>
        <tbody>
          {data.map((row, index) => (
            <tr key={index}>
              <td >
                <div className="region-name">{row.location}</div>
              </td>
              {["co", "so2", "ch4", "no2", "o3"].map((key) => {
                const { color, trendSymbol } = getTrendInfo(row[key].trend); // Get color and trend symbol
                return (
                  <td key={key}>
                    <div className="gas-name">{row[key].value}</div>
                    <div
                      style={{
                        fontSize: "0.8rem",
                        marginTop: "6px",
                        color: color, 
                      }}
                    >
                      {trendSymbol} {row[key].trend}
                    </div>
                  </td>
                );
              })}
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default SubRegion;
