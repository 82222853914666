import React, { useEffect, useState } from "react"; 
import axios from "axios";
import ReactApexChart from "react-apexcharts";
import graph from "../../../assets/Emission/MapAnalytics/graph-icon.svg";

const EmissionTrend = ({ selectedLocation, selectedTime }) => {
  const [series, setSeries] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [chartHeight, setChartHeight] = useState(350); // Default chart height

  useEffect(() => {
    // Set chart height based on screen height
    if (window.innerHeight >= 800) {
      setChartHeight(400);
    }

    const fetchEmissionTrends = async () => {
      const timeFrameMap = {
        "Past 1 Month": "1_month",
        "Past 3 Months": "3_months",
        "Past 6 Months": "6_months",
        "Past 1 Year": "1_year",
      };
      const timeParam = timeFrameMap[selectedTime] || "6_months";

      const url = `${process.env.REACT_APP_BE_URL}/emissions/emission-trends/`;

      try {
        const response = await axios.get(url, {
          params: {
            location: selectedLocation,
            timeframe: timeParam,
          },
          headers: {
            Authorization: `Bearer ${localStorage.getItem("access_key")}`,
            "Content-Type": "application/json",
          },
        });

        const data = response.data;

        // Transform API data to match chart requirements
        const transformedSeries = data.map((item) => ({
          name: `${item.gas} (${item.unit})`,
          data: item.trend.map((trendItem) => ({
            x: trendItem.month,
            y: parseFloat(trendItem.avg_measurement.toFixed(2)),
          })),
        }));

        setSeries(transformedSeries);
        setLoading(false);
      } catch (err) {
        console.error("Error fetching emission trends:", err);
        setError("Failed to fetch emission trends data.");
        setLoading(false);
      }
    };

    fetchEmissionTrends();
  }, [selectedLocation, selectedTime]);

  const options = {
    chart: {
      type: "line",
      toolbar: {
        show: true,
        tools: {
          download: true,
          pan: false,
          zoom: false,
          zoomin: false,
          zoomout: false,
          reset: false,
        },
      },
    },
    xaxis: {
      title: { text: "Month", style: { color: "#025864", fontSize: "12px" } },
      labels: { style: { fontSize: "12px" } },
    },
    yaxis: {
      title: {
        text: "Concentration",
        style: { color: "#025864", fontSize: "12px" },
      },
    },
    colors: ["#173F5F", "#3CAEA3", "#2576B9", "#F0C93A", "#ED553B"],
    stroke: {
      width: 2,
      curve: "straight",
    },
    markers: {
      size: 4,
    },
    legend: {
      position: "bottom",
      horizontalAlign: "center",
      fontSize: "12px",
      markers: {
        radius: 12,
      },
      itemMargin: {
        horizontal: 10,
        vertical: 5,
      },
    },
    tooltip: {
      shared: true,
      intersect: false,
      y: {
        formatter: (val) => `${val}`,
      },
    },
  };

  return (
    <div className="emission-chart-container">
      <div className="emission-heading-container">
        <img src={graph} alt="Graph Icon" className="graph-icon" />
        <div className="emission-heading">Emission Trend Graph</div>
      </div>
      
      {loading && (
        <div style={{ fontStyle: 'italic' }}>Loading</div>
      )}
      
      {error && (
        <div style={{ color: 'red', marginTop: '10px', textAlign: 'center' }}>{error}</div>
      )}
      
      {!loading && !error && (
        <ReactApexChart options={options} series={series} type="line" height={chartHeight} />
      )}
    </div>
  );
};

export default EmissionTrend;
