import React, { useState } from 'react';
import ThresholdTrend from './ThresholdTrend';
import ThresholdAlert from './ThresholdAlert';
import EmissionComparison from './EmissionComparison';
import TopContributionRegions from './TopContribution';
import SubRegionalComponent from './SubRegion';

const Analytics = ({selectedLocation, selectedTime}) => {
  const [selectedTab, setSelectedTab] = useState('Analytic'); // Default selection is "Analytic"

  return (
    <div className='analytics-container'>
        <div className='analytics-header'>
          <h3
            className={selectedTab === 'Analytic' ? 'active-tab' : ''}
            onClick={() => setSelectedTab('Analytic')}
          >
            Analytics
          </h3>
          <h3
            className={selectedTab === 'Sub-regional' ? 'active-tab' : ''}
            onClick={() => setSelectedTab('Sub-regional')}
          >
            Sub-regional analysis
          </h3>
        </div>
        <div className='analytics-divider'></div>
        {selectedTab === 'Analytic' && (
        <>
          <TopContributionRegions selectedLocation={selectedLocation} selectedTime={selectedTime} />
          <div className='analytics-component-divider'></div>
          <ThresholdTrend selectedLocation={selectedLocation} selectedTime={selectedTime}/>
          <div className='analytics-component-divider'></div>
          <ThresholdAlert selectedLocation={selectedLocation} selectedTime={selectedTime}/>
          <div className='analytics-component-divider'></div>
          <EmissionComparison selectedLocation={selectedLocation} />
        </>
      )}

      {selectedTab === 'Sub-regional' && (
        <>
          <SubRegionalComponent selectedLocation={selectedLocation} selectedTime={selectedTime} />
        </>
      )}
    </div>
  )
}

export default Analytics
