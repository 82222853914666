import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Dashboard from './container/Dashboard';
import Section from './container/Section';
import Login from "./container/Login";
import AppPage from "./components/Login/LoginDetails"
import './App.css';
import './css/index.css'
import NavigationBar from "./components/NavigationBar";
import Carousels from "./components/Login/Carousel"
import EmissionPage from './container/EmissionPage';
import MapAnalytics from './container/MapPage';
import Report from './container/ReportPage';
import Profile from './container/ProfilePage';

function App() {
  return (
    <div className="App">
          <Router>
          {/* {window.location.pathname === "/dashboard" ? "" : <NavigationBar />} */}
            <Routes>
              <Route path="/*" element={<Login />} />
              <Route path="/dashboard" element={<Dashboard />} />
              <Route path="/section" element={<Section />} />
              <Route path="/dashboard/emission" element={<EmissionPage />} /> 
              <Route path="/map-wise" element={<MapAnalytics />} />
              <Route path="/report" element={<Report />} />
              <Route path="/profile" element={<Profile />} />
            </Routes>
          </Router>
    </div>
  );
}

export default App;
