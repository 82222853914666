import React, { useState, useEffect } from "react";
import { Menu } from "antd";
import axios from "axios";
import gasIcon from "../../../assets/Emission/MapAnalytics/gas-icon.svg";
import decreaseArrow from "../../../assets/Emission/MapAnalytics/decrease.svg";
import increaseArrow from "../../../assets/Emission/MapAnalytics/increase.svg";

const GHG_Rate = ({ selectedLocation, selectedTime }) => { // Accept selectedTime as a prop
  const [emissions, setEmissions] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      const timeFrameMap = {
        "Past 1 Month": "1_month",
        "Past 3 Months": "3_months",
        "Past 6 Months": "6_months",
        "Past 1 Year": "1_year",
      };
      const timeParam = timeFrameMap[selectedTime] || "6_months";

      const url = `${process.env.REACT_APP_BE_URL}/emissions/ghg-emission-rates/`;

      try {
        const response = await axios.get(url, {
          params: {
            location: selectedLocation,
            timeframe: timeParam, // Use timeframe parameter
          },
          headers: {
            Authorization: `Bearer ${localStorage.getItem("access_key")}`,
            "Content-Type": "application/json",
          },
        });

        setEmissions(response.data?.ghg_rates || []);
        setLoading(false);
      } catch (err) {
        console.error("Error fetching GHG emission rates:", err.response || err);
        setError(err.message || "An error occurred while fetching data.");
        setLoading(false);
      }
    };

    fetchData();
  }, [selectedLocation, selectedTime]); // Re-fetch on location or time change

  const getChangeText = (percentChange) => {
    let changeText = "since last month";

    switch (selectedTime) {
      case "Past 1 Month":
        changeText = "since last month";
        break;
      case "Past 3 Months":
        changeText = "since last 3 months";
        break;
      case "Past 6 Months":
        changeText = "since last 6 months";
        break;
      case "Past 1 Year":
        changeText = "since last year";
        break;
      default:
        break;
    }

    return `${Math.abs(percentChange).toFixed(2)}% ${
      percentChange > 0 ? "increase" : "decrease"
    } ${changeText}`;
  };

  return (
    <div className="ghg-container">
      <div className="emission-heading-row">
        <div className="emission-heading">GHG emission rates</div>
      </div>
      {loading && <div style={{ fontStyle: "italic", marginTop: "10px" }}>Loading</div>}
      {error && <div style={{ color: "red", marginTop: "10px" }}>Error: {error}</div>}
      {!loading && !error && (
        <div className="ghg-all-cards">
          {emissions.map((item, index) => (
            <div key={index} className="ghg-card">
              <div className="emission-rate-header">
                <img src={gasIcon} alt="Gas Icon" className="gas-icon" />
                <div className="emission-title">{item.gas}</div>
              </div>
              <div className="emission-value">
                {item.avg_measurement.toFixed(4)} {item.unit}
              </div>
              <div
                className="emission-change"
                style={{
                  color: item.percent_change > 0 ? "red" : "green",
                }}
              >
                <img
                  src={item.percent_change > 0 ? increaseArrow : decreaseArrow}
                  alt={item.percent_change > 0 ? "increase" : "decrease"}
                  className="arrow-icon"
                />
                {getChangeText(item.percent_change)}
              </div>
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

export default GHG_Rate;
